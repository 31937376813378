#nav {
  width: 100%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-grow: 1;
}

/*
#nav li a {
      display: inline-block;
      padding: 8px 15px;
      text-decoration: none;
      font-weight: bold;
      color: #069;
      border-right: 1px solid #ccc; }
      */
      
.nav-ul > .nav-li {
  display: inline-block;
  text-align: center;
  margin:1%;
  vertical-align: auto;
  /* You can also add some margins here to make it look prettier */
  /* zoom:1; */
  /* *display:inline; */
  /* this fix is needed for IE7- */
}
      
*.nav-li {
  /* padding-top: 40px; */
}


.App {
  text-align: left;
  max-width: 700px;
  margin: auto;
  margin-bottom: 50px;
  padding-left: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 

.label-normal {
  font-style: normal;
}
.label-italic {
  font-style: italic;
}
.bullet {
  max-width: 600px;
  margin-left: 25px;
}
.bullet-label {
  
  margin-left: 17px;
  
}
.dates {
  
  margin-left: 17px;
  font-style: italic;
}
.subentry {
  margin-left: 20px;
}
h2 {
  
  margin-left: 10px;
  margin-top: 50px;
}
h3 {
  
  margin-left: 10px;
}
h4 {
  margin-left: 10px;
}

.reference {
  text-indent: -50px;
  padding-left: 50px;
  max-width: 600px;
}

.md {
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
} */

blockquote {
  /* padding-top: 10px; */
}